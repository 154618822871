import request from "@/utils/request.ts";

// 在场人数
export const countOnSite = (args: any) => {
  return request({
    url: "/project/accessRecord/countOnSite",
    method: "get",
    params: args,
  });
};

// 今日考勤信息
export const mobilecheckintoday = (args: any) => {
  return request({
    url: "/center/mobile/checkin/today",
    method: "get",
    params: args,
  });
};

// 出勤率
export const mobilecheckinrate = (args: any) => {
  return request({
    url: "/center/mobile/checkin/rate",
    method: "get",
    params: args,
  });
};

// 考勤列表
export const mobilecheckinlist = (args: any) => {
  return request({
    url: "/center/mobile/checkin/list",
    method: "get",
    params: args,
  });
};
